<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <div class="mt-1">
            <b-media>
              <template #aside>
                <b-avatar rounded
                  size="60"
                  :src="userData.avatar"
                  variant="light-primary"
                  badge
                  class="badge-minimal"
                  badge-variant="success"
                >
                  <feather-icon
                    v-if="!userData.email"
                    icon="UserIcon"
                    size="60"
                  />
                </b-avatar>
              </template>
              <h5 class="text-capitalize mb-75">Email</h5>
              <b-card-text>{{ userData.email }}</b-card-text>
            </b-media>
          </div>
          <!-- <div class="mt-2">
            <h5 class="mb-75">Language: {{ userData.language }}</h5>
          </div> -->
          <!-- <div class="mt-2">
            <h5 class="mb-75">Phone: {{ userData.phone }}</h5>
          </div> -->
        </b-card>
      </b-col>

      <!-- <b-col cols="8">
        <b-card>
          <div>
            <h5 class="mb-75 text-capitalize">Group: {{ userData.group_id }}</h5>
          </div>
        </b-card>
        <b-card>
          <div>
            <h5 class="mb-75 text-capitalize">Permissions:  {{ getRole() }}</h5>
          </div>
        </b-card>
      </b-col> -->
    </b-row>
  </div>
</template>
<script>
import service from "../../admin_user/service";
export default {
  data() {
    return {
      roles: []
    }
  },
  computed: {
    userData() {
      return this.$store.getters['auth/userData']
    },
  },
  created() {
    this.roles = service.getRoles()
  },
  methods: {
    getRole() {
      let permision;
      if (this.userData.role) {
        permision = this.roles[this.userData.role]
      }
      return permision
    }
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
.media {
  overflow-wrap: anywhere;
}
</style>
